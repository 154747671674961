<template>
    <div class="notificationWindow" :style="{background: background}">
        <NotificationWidget :manager="$account.variationManager" />
    </div>
</template>
  
<script>
import NotificationWidget from "./notificationWidget";

export default {
    components: {
        NotificationWidget,
    },
 
    watch: {
        "$firebase.account"() {
            this.Reload();
        },
        "$donatrix.settingsReady"() {
            this.Reload();
        },
    },
    mounted() {
        this.Reload();
    },
    
    computed: {
        background() {
            return this.$account.variationManager?.variations?.[0]?.chromaColorSetting?.actualValue
        }
    },

    methods: {
        Reload() {
            if (!this.$firebase.account) return;
            if (!this.$donatrix.settingsReady) return;
            this.$account.variationManager.LoadVariations();
            this.$messages.StartListen();
        },
    },
};
</script>
  
<style scoped lang="scss">
.notificationWindow {
    display: flex;
}
</style>
  