<template>
  <div class="message" :class="{'show': message}">
    <img class="logo" src="../../../public/images/logoMini.png" alt="logo">
    <div class="info">
      <div class="top">
        {{ message?.nickname }} - {{ message?.nativeValue}} {{ message?.ticker }}
      </div>

      <div class="text">
        {{ message?.text }}
      </div>
    </div>
  </div>
</template>
  
<script>
import { Message } from "../../code/classes/Message";
import { NotificationVariationManager } from "../../code/classes/NotificationVariationManager";
import { NotificationVariationRuntime } from "../../code/classes/NotificationVariationRuntime";

export default {
  props: {
    manager: { type: NotificationVariationManager, default: undefined },
  },

  data() {
    return {
      message: undefined,
      variation: undefined,
    };
  },

  mounted() {
    this.manager?.RegisterDisplay(this.DisplayMessage.bind(this));
  },

  destroyed() {
    this.manager?.UnregisterDisplay();
    clearTimeout(this.h);
  },

  methods: {
    DisplayMessage(message, variation) {
      if (!(message instanceof Message)) throw "unexpected message type";
      if (!(variation instanceof NotificationVariationRuntime)) throw "unexpected variation type";
      this.message = message;
      this.variation = variation;

      clearTimeout(this.h);
      this.h = setTimeout(() => {
        this.message = undefined;
        this.variation = undefined;
      }, variation.alertDurationSeconds * 1000);
    },
  },
};
</script>
  
<style scoped lang="scss">
.message {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  opacity: 0;
}

.show {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.logo {
  width: 93px;
  height: 107px;
}

.top {
  font-family: Gilroy-Medium;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background: var(--0, linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text {
  margin-top: 14px;
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  max-width: 300px;
  overflow-wrap: anywhere;
}

.removing {
  user-select: none;
  pointer-events: none;
  opacity: 0.8;
}

.fake {
  background-image: repeating-linear-gradient(-45deg, #f702, #f702 8px, #0000 8px, #0000 22px);
}
</style>
  